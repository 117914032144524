@each $variant in map-get($headings, 'variant') {
  @each $t, $s in $variant {
    #{$t} {
      font-family: 'heading', sans-serif;
      font-weight: normal;
      font-size: pxToRem($s);
      line-height: pxToRem($s);
    }
  }
}
