.Article {
  display: flex;
  //margin-bottom: 5vh;
  margin-top: 5vh;
  min-height: 260px;

  .article-content {
    padding-left: 10%;
    padding-right: 10%;
    flex: 1.3;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .article-intro {
      font-style: italic;
    }

    .article-content-list {
      img {
        margin: 20px 0;
      }
    }
  }
  // XS - Phone
  @media (max-width: 480px) {
  }

  // S - Tablet
  @media (max-width: 768px) {
    width: 100%;
    display: block;
    margin: 0;

    .article-content {
      padding-left: 10%;
      padding-right: 10%;
      margin-bottom: 40px;
      position: relative;
    }
  }

  // M - Tablet/Laptop
  @media (max-width: 769px) and (max-width: 1024px) {
  }

  // L - High Res
  @media (min-width: 1025px) and (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
