/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
a {
  text-decoration: none;
}

/** FONTS **/
/*! main-text-regular */
@font-face {
  font-family: "main-text-regular";
  src: url("../fonts/freesans.woff") format("woff"), url("../fonts/freesans.woff2") format("woff2");
}
/*! main-text-bold */
@font-face {
  font-family: "main-text-bold";
  src: url("../fonts/freesansbold.woff") format("woff"), url("../fonts/freesansbold.woff2") format("woff2");
}
/*! main-text-link */
@font-face {
  font-family: "main-text-link";
  src: url("../fonts/freesansbold.woff") format("woff"), url("../fonts/freesansbold.woff2") format("woff2");
}
/*! label-text */
@font-face {
  font-family: "label-text";
  src: url("../fonts/freesans.woff") format("woff"), url("../fonts/freesans.woff2") format("woff2");
}
/*! slogan-text */
@font-face {
  font-family: "slogan-text";
  src: url("../fonts/bariol_italic.woff") format("woff"), url("../fonts/bariol_italic.woff2") format("woff2");
}
/*! heading */
@font-face {
  font-family: "heading";
  src: url("../fonts/bariol_bold.woff") format("woff"), url("../fonts/bariol_bold.woff2") format("woff2");
}
/** CUSTOM PROPERTIES **/
:root {
  /** COLOR **/
  /*! Background Color */
  /*! black */
  --bg-black: #000;
  /*! black-muted */
  --bg-black-muted: rgba(0, 0, 0, 0.6);
  /*! white */
  --bg-white: #FFF;
  /*! white-muted */
  --bg-white-muted: rgba(255, 255, 255, 0.6);
  /*! dark-blue */
  --bg-dark-blue: #2A679A;
  --bg-dark-blue-light-20: #589ad2;
  --bg-dark-blue-dark-20: #14314a;
  /*! medium-blue */
  --bg-medium-blue: #3D8ACB;
  --bg-medium-blue-light-20: #8dbbe1;
  --bg-medium-blue-dark-20: #225580;
  /*! light-blue */
  --bg-light-blue: #5E99CB;
  --bg-light-blue-light-20: #abcae4;
  --bg-light-blue-dark-20: #306693;
  /*! Foreground Color */
  /*! black */
  --fg-black: #000;
  /*! black-muted */
  --fg-black-muted: rgba(0, 0, 0, 0.6);
  /*! white */
  --fg-white: #FFF;
  /*! white-muted */
  --fg-white-muted: rgba(255, 255, 255, 0.6);
  /*! dark-blue */
  --fg-dark-blue: #2A679A;
  --fg-dark-blue-light-20: #589ad2;
  --fg-dark-blue-dark-20: #14314a;
  /*! medium-blue */
  --fg-medium-blue: #3D8ACB;
  --fg-medium-blue-light-20: #8dbbe1;
  --fg-medium-blue-dark-20: #225580;
  /*! light-blue */
  --fg-light-blue: #5E99CB;
  --fg-light-blue-light-20: #abcae4;
  --fg-light-blue-dark-20: #306693;
  /** GRADIENTS **/
  --main-gradient: linear-gradient(30deg, #7DAAD0, #3D8ACB);
  --DO-NOT-USE-gradient: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  /** FONT **/
  --main-text-regular: "main-text-regular";
  --main-text-bold: "main-text-bold";
  --main-text-link: "main-text-link";
  --label-text: "label-text";
  --slogan-text: "slogan-text";
  --background-transition: background 0.4s ease;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--bg-white);
  font-family: "main-text-regular", sans-serif;
  overflow-x: hidden !important;
}

@media (max-width: 768px) {
  body {
    margin: 0;
    padding: 0;
  }
}
.mobile-image {
  display: none;
}

.separator {
  margin-top: 220px;
}

@media (max-width: 480px) {
  .separator {
    margin-top: 0;
  }
}
@media (max-width: 768px) {
  .separator {
    margin-top: 0;
  }
  .mobile-image {
    display: block;
    margin-top: 0;
    margin-bottom: 20px;
    height: 220px !important;
    background-position: center center;
    background-size: cover;
  }
  .mobile-image .swooshed {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 140%;
    height: 120px;
    border: 1px solid lime;
  }
  .mobile-image .swooshed img {
    height: 220px;
  }
}
/** UTILITY: Colors **/
/*! Background Color */
/*! black */
.bg-black {
  background-color: var(--bg-black);
}

/*! black-muted */
.bg-black-muted {
  background-color: var(--bg-black-muted);
}

/*! white */
.bg-white {
  background-color: var(--bg-white);
}

/*! white-muted */
.bg-white-muted {
  background-color: var(--bg-white-muted);
}

/*! dark-blue */
.bg-dark-blue {
  background-color: var(--bg-dark-blue);
}

.bg-dark-blue-light-20 {
  background-color: var(--bg-dark-blue-light-20);
}

.bg-dark-blue-dark-20 {
  background-color: var(--bg-dark-blue-dark-20);
}

/*! medium-blue */
.bg-medium-blue {
  background-color: var(--bg-medium-blue);
}

.bg-medium-blue-light-20 {
  background-color: var(--bg-medium-blue-light-20);
}

.bg-medium-blue-dark-20 {
  background-color: var(--bg-medium-blue-dark-20);
}

/*! light-blue */
.bg-light-blue {
  background-color: var(--bg-light-blue);
}

.bg-light-blue-light-20 {
  background-color: var(--bg-light-blue-light-20);
}

.bg-light-blue-dark-20 {
  background-color: var(--bg-light-blue-dark-20);
}

/*! Foreground Color */
/*! black */
.fg-black {
  color: var(--fg-black);
}

/*! black-muted */
.fg-black-muted {
  color: var(--fg-black-muted);
}

/*! white */
.fg-white {
  color: var(--fg-white);
}

/*! white-muted */
.fg-white-muted {
  color: var(--fg-white-muted);
}

/*! dark-blue */
.fg-dark-blue {
  color: var(--fg-dark-blue);
}

.fg-dark-blue-light-20 {
  color: var(--fg-dark-blue-light-20);
}

.fg-dark-blue-dark-20 {
  color: var(--fg-dark-blue-dark-20);
}

/*! medium-blue */
.fg-medium-blue {
  color: var(--fg-medium-blue);
}

.fg-medium-blue-light-20 {
  color: var(--fg-medium-blue-light-20);
}

.fg-medium-blue-dark-20 {
  color: var(--fg-medium-blue-dark-20);
}

/*! light-blue */
.fg-light-blue {
  color: var(--fg-light-blue);
}

.fg-light-blue-light-20 {
  color: var(--fg-light-blue-light-20);
}

.fg-light-blue-dark-20 {
  color: var(--fg-light-blue-dark-20);
}

.main-text-regular-12 {
  font-family: "main-text-regular";
  font-size: 0.75rem;
}

.main-text-regular-14 {
  font-family: "main-text-regular";
  font-size: 0.875rem;
}

.main-text-regular-16 {
  font-family: "main-text-regular";
  font-size: 1rem;
}

.main-text-regular-18 {
  font-family: "main-text-regular";
  font-size: 1.125rem;
}

.main-text-regular-20 {
  font-family: "main-text-regular";
  font-size: 1.25rem;
}

.main-text-bold-12 {
  font-family: "main-text-bold";
  font-size: 0.75rem;
}

.main-text-bold-14 {
  font-family: "main-text-bold";
  font-size: 0.875rem;
}

.main-text-bold-16 {
  font-family: "main-text-bold";
  font-size: 1rem;
}

.main-text-bold-18 {
  font-family: "main-text-bold";
  font-size: 1.125rem;
}

.main-text-bold-20 {
  font-family: "main-text-bold";
  font-size: 1.25rem;
}

.main-text-link-12 {
  font-family: "main-text-link";
  font-size: 0.75rem;
}

.main-text-link-14 {
  font-family: "main-text-link";
  font-size: 0.875rem;
}

.main-text-link-16 {
  font-family: "main-text-link";
  font-size: 1rem;
}

.label-text-12 {
  font-family: "label-text";
  font-size: 0.75rem;
}

.label-text-14 {
  font-family: "label-text";
  font-size: 0.875rem;
}

.label-text-16 {
  font-family: "label-text";
  font-size: 1rem;
}

.label-text-18 {
  font-family: "label-text";
  font-size: 1.125rem;
}

.label-text-20 {
  font-family: "label-text";
  font-size: 1.25rem;
}

.label-text-22 {
  font-family: "label-text";
  font-size: 1.375rem;
}

.label-text-24 {
  font-family: "label-text";
  font-size: 1.5rem;
}

.label-text-26 {
  font-family: "label-text";
  font-size: 1.625rem;
}

.label-text-28 {
  font-family: "label-text";
  font-size: 1.75rem;
}

.slogan-text-28 {
  font-family: "slogan-text";
  font-size: 1.75rem;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.is-flex {
  display: flex;
}

.is-row {
  flex-direction: row;
}

.is-column {
  flex-direction: column;
}

.float-right {
  justify-content: flex-end;
}

.float-start {
  justify-content: flex-start;
}

.float-center {
  justify-content: center;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

h1 {
  font-family: "heading", sans-serif;
  font-weight: normal;
  font-size: 2.625rem;
  line-height: 2.625rem;
}

h2 {
  font-family: "heading", sans-serif;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2rem;
}

h3 {
  font-family: "heading", sans-serif;
  font-weight: normal;
  font-size: 1.75rem;
  line-height: 1.75rem;
}

h4 {
  font-family: "heading", sans-serif;
  font-weight: normal;
  font-size: 1.625rem;
  line-height: 1.625rem;
}

h5 {
  font-family: "heading", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

h6 {
  font-family: "heading", sans-serif;
  font-weight: normal;
  font-size: 1.375rem;
  line-height: 1.375rem;
}

.Logo {
  z-index: 15000;
  margin-bottom: 20px;
  cursor: pointer;
}
.Logo img {
  height: 60px;
}
.TextParagraph {
  font-family: "main-text-regular", sans-serif;
  color: var(--fg-black-muted);
  margin-bottom: 20px;
}
.Heading {
  margin-bottom: 20px;
}
.IntroductionText {
  color: var(--bg-medium-blue);
  font-family: "main-text-bold", sans-serif;
  font-size: 0.85rem;
  margin-bottom: 20px;
}
.SideImage {
  position: absolute;
  height: 260px;
  background-size: cover;
  background-repeat: no-repeat;
  width: 480px;
}
.SideImage[data-object-type=right] {
  background-position: center left;
  border-top-left-radius: 130px;
  border-bottom-left-radius: 130px;
  float: right;
  margin-right: -35px;
  right: 0;
}
.SideImage[data-object-type=right] .swoosh-container {
  display: flex;
  align-items: center;
  width: 140%;
  height: 260px;
  margin-left: -10px;
}
.SideImage[data-object-type=left] {
  background-position: center right;
  border-top-right-radius: 130px;
  border-bottom-right-radius: 130px;
  float: left;
  margin-left: -1.75px;
  left: 0;
  width: 480px;
}
.SideImage[data-object-type=left] .swoosh-container {
  display: flex;
  align-items: center;
  width: 140%;
  height: 260px;
  margin-left: -180px;
  transform: scaleX(-1);
}
@media (max-width: 768px) {
  .SideImage {
    display: none !important;
  }
}
@media (max-width: 1024px) {
  .SideImage {
    width: 280px;
  }
  .SideImage[data-object-type=left] {
    width: 280px !important;
  }
  .SideImage[data-object-type=right] {
    width: 280px !important;
  }
}
.Button {
  color: var(--fg-white);
  background-color: var(--bg-medium-blue);
  display: inline-block;
  padding: 6px 30px;
  border-radius: 15px;
  font-size: 0.85rem;
  transition: var(--background-transition);
  cursor: pointer;
  text-align: center;
  min-width: 120px;
  z-index: 100000;
}
.Button:hover {
  background-color: var(--bg-dark-blue);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
}
.MenuTrigger {
  position: fixed;
  top: 10px;
  right: 20px;
  width: 80px;
  height: 80px;
  z-index: 20000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--bg-white-muted);
}
.MenuTrigger span:nth-of-type(1) {
  width: 50%;
}
.MenuTrigger span:nth-of-type(2) {
  width: 100%;
}
.MenuTrigger span:nth-of-type(3) {
  width: 75%;
}
.MenuTrigger input[type=checkbox] {
  display: none;
}
.MenuTrigger input[type=checkbox]:checked .MenuOverlay {
  border: 10px solid green;
  display: block;
}
.MenuTrigger input[type=checkbox]:checked ~ span:nth-of-type(1) {
  transform-origin: bottom;
  transform: rotatez(45deg) translate(8px, 0px);
}
.MenuTrigger input[type=checkbox]:checked ~ span:nth-of-type(2) {
  transform-origin: top;
  transform: rotatez(-45deg);
}
.MenuTrigger input[type=checkbox]:checked ~ span:nth-of-type(3) {
  transform-origin: bottom;
  width: 50%;
  transform: translate(30px, -11px) rotatez(45deg);
}
.Section {
  display: flex;
  margin-bottom: 10vh;
  margin-top: 5vh;
  min-height: 260px;
  overflow: hidden;
}
.Section .section-content {
  padding-left: 10%;
  padding-right: 10%;
  flex: 1.3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Section[data-object-type=slogan] {
  height: 260px;
  background-image: var(--main-gradient) !important;
  border-top-right-radius: 130px;
  border-bottom-right-radius: 130px;
  margin-right: 15%;
}
.Section[data-object-type=slogan] .section-content {
  position: relative;
  padding-left: 10%;
  padding-right: 10%;
  height: 260px;
  flex: 1.3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Section[data-object-type=slogan] .section-content h2 {
  font-family: var(--slogan-text);
  font-size: 32px;
  font-weight: normal !important;
  color: var(--fg-white);
  margin: 0;
  padding: 0;
}
.Section .section-image {
  flex: 1;
}
@media (max-width: 768px) {
  .Section {
    width: 100%;
    display: block;
    margin: 0;
  }
  .Section .section-content {
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 40px;
    position: relative;
  }
  .Section[data-object-type=slogan] {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.ButtonBar {
  display: flex;
  align-items: center;
  gap: 20px;
}
.Header {
  padding-left: 5%;
  padding-top: 5%;
}
.Footer {
  background-color: var(--bg-dark-blue);
  min-height: 160px;
  width: 100%;
  padding: 3%;
  padding-left: 5%;
  display: flex;
  align-items: center;
  gap: 40px;
}
.Footer ul {
  margin-left: -40px;
  list-style: none;
}
.Footer ul li {
  margin-bottom: 5px;
  color: var(--fg-white);
}
.Footer ul li a {
  color: var(--fg-white);
  font-weight: bold;
}
.Footer ul li a:hover {
  text-decoration: underline;
}
.Footer .Logo img {
  height: 40px;
}
.Article {
  display: flex;
  margin-top: 5vh;
  min-height: 260px;
}
.Article .article-content {
  padding-left: 10%;
  padding-right: 10%;
  flex: 1.3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Article .article-content .article-intro {
  font-style: italic;
}
.Article .article-content .article-content-list img {
  margin: 20px 0;
}
@media (max-width: 768px) {
  .Article {
    width: 100%;
    display: block;
    margin: 0;
  }
  .Article .article-content {
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 40px;
    position: relative;
  }
}
.FurtherReading {
  position: absolute;
  margin-top: 310px;
  margin-right: -35px;
  width: 480px;
  min-height: 260px;
  float: right;
  right: 0;
  background-image: var(--main-gradient);
  border-top-left-radius: 130px;
  border-bottom-left-radius: 130px;
  padding-left: 80px;
  padding-top: 60px;
  padding-bottom: 40px;
}
.FurtherReading ul {
  margin-left: -40px;
  list-style: none;
}
.FurtherReading ul li a {
  cursor: pointer;
  color: var(--fg-white-muted);
  transition: var(--transition);
}
.FurtherReading ul li a:hover {
  color: var(--fg-white);
}
.FurtherReading .reading-content {
  color: var(--fg-white) !important;
}
@media (max-width: 480px) {
  .FurtherReading {
    display: none;
  }
}
@media (max-width: 768px) {
  .FurtherReading {
    display: none;
  }
}
@media (max-width: 1024px) {
  .FurtherReading {
    width: 280px;
    padding-left: 40px;
  }
}
.MenuOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--bg-white);
  z-index: 10000;
  transition: all 0.4s ease-in-out;
}
.ListMenu {
  padding: 5%;
}
.ListMenu .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  padding: 5%;
}
.ListMenu .menu-item {
  width: 50%;
  padding: 20px 0;
  margin-bottom: 10px;
  text-align: left;
  overflow-y: hidden;
  position: relative;
  cursor: pointer;
  color: var(--fg-medium-blue-light-20);
  transition: var(--transition);
  opacity: 0.8;
}
.ListMenu .menu-item h5:hover {
  color: var(--fg-medium-blue);
  opacity: 1;
}
@media (max-width: 480px) {
  .ListMenu {
    padding: 5%;
  }
  .ListMenu .menu-item {
    width: 95%;
  }
}
@media (max-width: 768px) {
  .ListMenu {
    padding: 5%;
  }
  .ListMenu .menu-item {
    width: 95%;
  }
}
.Homepage {
  padding: 60px;
  overflow-x: hidden !important;
  position: relative;
}
@media (max-width: 768px) {
  .Homepage {
    padding: 0;
    overflow-x: hidden !important;
    position: relative;
  }
}
.Detail {
  padding: 60px;
  overflow-x: hidden !important;
  position: relative;
}
@media (max-width: 768px) {
  .Detail {
    padding: 0;
    overflow-x: hidden !important;
    position: relative;
  }
}

