/** FONTS **/
@each $entry in $fonts {
  $type: map-get($entry, 'type');
  $uri: map-get($entry, 'uri');
  /*! #{$type} */
  @font-face {
    font-family: '#{$type}';
    src: url($uri + '.woff') format('woff'),
      /* Modern Browsers */ url($uri + '.woff2') format('woff2'),
      /* Modern Browsers */;
  }
}
