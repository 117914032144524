.FurtherReading {
  $minHeight: 260px;
  $margin: -35px;
  $width: 480px;
  $mob-width: 280px;

  position: absolute;
  margin-top: $minHeight + 50px;
  margin-right: $margin;
  width: $width;
  min-height: $minHeight;
  float: right;
  right: 0;
  background-image: var(--main-gradient);
  border-top-left-radius: $minHeight / 2;
  border-bottom-left-radius: $minHeight / 2;
  padding-left: 80px;
  padding-top: 60px;
  padding-bottom: 40px;

  ul {
    margin-left: -40px;
    list-style: none;

    li a {
      cursor: pointer;
      color: var(--fg-white-muted);
      transition: var(--transition);
      &:hover {
        color: var(--fg-white);
      }
    }
  }

  .reading-content {
    color: var(--fg-white) !important;
  }

  // XS - Phone
  @media (max-width: 480px) {
    display: none;
  }

  // S - Tablet
  @media (max-width: 768px) {
    display: none;
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
    width: $mob-width;
    padding-left: 40px;
  }

  // L - High Res
  @media (min-width: 1025px) and (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
