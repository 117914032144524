/** CUSTOM PROPERTIES **/
:root {
  /** COLOR **/
  @each $ct, $cd in $color-types {
    /*! #{$cd} */
    @each $style in $colors {
      $type: map-get($style, 'type');
      /*! #{$type} */
      $shades: rootColor($type, $ct, $style);
      @each $shade, $value in $shades {
        #{$shade}: #{$value};
      }
    }
  }

  /** GRADIENTS **/
  @each $gradient in $gradients {
    @debug ($gradient);
    $type: map-get($gradient, 'type');
    $start: map-get($gradient, 'start');
    $end: map-get($gradient, 'end');
    $angle: map-get($gradient, 'angle');

    --#{$type}-gradient: linear-gradient(#{$angle}deg, #{$start}, #{$end});
  }

  /** FONT **/
  @each $entry in $fonts {
    $type: map-get($entry, 'type');
    $q: quote($type);
    //--#{$type}: '#{$type}';
    --#{$type}: '#{$q}';
  }

  --background-transition: background 0.4s ease;
}
