body {
  margin: map-get($page, 'margin');
  padding: map-get($page, 'padding');
  background-color: var(--bg- + map-get($page, 'backgroundColor'));
  font-family: 'main-text-regular', sans-serif;
  overflow-x: hidden !important;
}

//@media (max-width: 480px) {
//}

// S - Tablet
@media (max-width: 768px) {
  body {
    margin: 0;
    padding: 0;
  }
}

.mobile-image {
  display: none;
}

.separator {
  margin-top: 220px;
}

@media (max-width: 480px) {
  .separator {
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .separator {
    margin-top: 0;
  }

  .mobile-image {
    display: block;
    margin-top: 0;
    margin-bottom: 20px;
    height: 220px !important;
    background-position: center center;
    background-size: cover;

    .swooshed {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 140%;
      height: 120px;
      border: 1px solid lime;

      img {
        height: 220px;
      }
      //margin-top: -400px;
    }
  }
}
