.Homepage {
  padding: 60px;
  overflow-x: hidden !important;
  position: relative;
  // XS - Phone
  //@media (max-width: 480px) {
  //}

  // S - Tablet
  @media (max-width: 768px) {
    padding: 0;
    overflow-x: hidden !important;
    position: relative;
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
