.SideImage {
  $height: 260px;
  $margin: -35px;
  $width: 480px;
  $mob-width: 280px;

  position: absolute;
  //overflow-x: hidden !important;
  height: $height;
  //width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  width: $width;

  &[data-object-type='right'] {
    background-position: center left;
    border-top-left-radius: $height / 2;
    border-bottom-left-radius: $height / 2;
    float: right;
    margin-right: $margin;
    right: 0;

    .swoosh-container {
      display: flex;
      align-items: center;
      width: 140%;
      height: $height;
      margin-left: -10px;
    }
  }

  &[data-object-type='left'] {
    background-position: center right;
    border-top-right-radius: $height / 2;
    border-bottom-right-radius: $height / 2;
    float: left;
    margin-left: $margin / 20;
    left: 0;
    width: $width;
    //overflow-x: hidden !important;

    .swoosh-container {
      display: flex;
      align-items: center;
      width: 140%;
      height: $height;
      margin-left: -180px;
      transform: scaleX(-1);
      //overflow-x: hidden !important;
    }
  }

  // XS - Phone
  @media (max-width: 480px) {
    //display: none !important;
  }

  // S - Tablet
  @media (max-width: 768px) {
    display: none !important;
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
    width: $mob-width;
    &[data-object-type='left'] {
      width: $mob-width !important;
    }
    &[data-object-type='right'] {
      width: $mob-width !important;
    }
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
