.Section {
  display: flex;
  margin-bottom: 10vh;
  margin-top: 5vh;
  min-height: 260px;
  overflow: hidden;

  .section-content {
    padding-left: 10%;
    padding-right: 10%;
    flex: 1.3;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &[data-object-type='slogan'] {
    $height: 260px;
    height: $height;
    background-image: var(--main-gradient) !important;
    border-top-right-radius: $height / 2;
    border-bottom-right-radius: $height / 2;
    margin-right: 15%;

    .section-content {
      position: relative;
      padding-left: 10%;
      padding-right: 10%;
      height: $height;
      flex: 1.3;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h2 {
        font-family: var(--slogan-text);
        font-size: 32px;
        font-weight: normal !important;
        color: var(--fg-white);
        margin: 0;
        padding: 0;
      }
    }
  }

  .section-image {
    flex: 1;
  }

  // XS - Phone
  //@media (max-width: 480px) {
  //}

  // S - Tablet
  @media (max-width: 768px) {
    width: 100%;
    display: block;
    margin: 0;

    .section-content {
      padding-left: 10%;
      padding-right: 10%;
      margin-bottom: 40px;
      position: relative;
    }

    &[data-object-type='slogan'] {
      width: 100%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      //margin-right: -25% !important;
    }
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
