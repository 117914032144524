.IntroductionText {
  color: var(--bg-medium-blue);
  font-family: 'main-text-bold', sans-serif;
  font-size: 0.85rem;
  margin-bottom: 20px;

  // XS - Phone
  @media (max-width: 480px) {
  }

  // S - Tablet
  @media (max-width: 768px) {
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
