.is-flex {
  display: flex;
}

.is-row {
  flex-direction: row;
}

.is-column {
  flex-direction: column;
}
