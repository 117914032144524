.ListMenu {
  padding: 5%;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    padding: 5%;
  }

  .menu-item {
    width: 50%;
    padding: 20px 0;
    margin-bottom: 10px;
    text-align: left;
    overflow-y: hidden;
    position: relative;
    cursor: pointer;
    color: var(--fg-medium-blue-light-20);
    transition: var(--transition);
    opacity: 0.8;
  }

  .menu-item h5:hover {
    color: var(--fg-medium-blue);
    //border-bottom: 5px solid #3D8ACB;
    opacity: 1;
  }

  // XS - Phone
  @media (max-width: 480px) {
    padding: 5%;
    .menu-item {
      width: 95%;
    }
  }

  // S - Tablet
  @media (max-width: 768px) {
    padding: 5%;
    .menu-item {
      width: 95%;
    }
  }

  // M - Tablet/Laptop
  @media (max-width: 769px) and (max-width: 1024px) {
  }

  // L - High Res
  @media (min-width: 1025px) and (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
