.Footer {
  background-color: var(--bg-dark-blue);
  min-height: 160px;
  width: 100%;
  padding: 3%;
  padding-left: 5%;
  display: flex;
  align-items: center;
  gap: 40px;

  ul {
    margin-left: -40px;
    list-style: none;
    li {
      margin-bottom: 5px;
      color: var(--fg-white);

      a {
        color: var(--fg-white);
        font-weight: bold;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }

  .Logo img {
    height: 40px;
  }
  // XS - Phone
  @media (max-width: 480px) {
  }

  // S - Tablet
  @media (max-width: 768px) {
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
