.Button {
  color: var(--fg-white);
  background-color: var(--bg-medium-blue);
  display: inline-block;
  padding: 6px 30px;
  border-radius: 15px;
  font-size: 0.85rem;
  transition: var(--background-transition);
  cursor: pointer;
  text-align: center;
  min-width: 120px;
  z-index: 100000;

  &:hover {
    background-color: var(--bg-dark-blue);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
  }
  // XS - Phone
  @media (max-width: 480px) {
  }

  // S - Tablet
  @media (max-width: 768px) {
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
